<template>
  <div class="login-container">
    <ModifyPassword
      :passwordVisible="passwordVisible"
      @changePasswordVisible="setPasswordVisible"
    ></ModifyPassword>
    <div class="login-box">
      <!--el-form是表单组件-->
      <!--ref是固定属性，每个表单都 必须有-->
      <!--:model,普通属性，名称为model，对表单数据对象进行绑定，与v-model没有关系，必须属性-->
      <!--label-width设置项目名称宽度，非必须属性-->
      <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules">
        <img :src="logo" alt />
        <!--匿名插槽应用体现-->
        <!--el-form-item：是el-form的子组件(表单域项目体现)，每个el-form内部都有若干该组件-->
        <!--label：项目名称，非必须的-->
        <!--prop:定义被校验项目的名字，为了兼容性好，该名字与v-model的名字一致-->
        <el-form-item prop="username">
          <!--匿名插槽应用体现-->
          <!--v-model:双向数据绑定，必须属性-->
          <el-input v-model="loginForm.username" placeholder="账号">
            <i slot="prefix" class="iconfont iconicon-test"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            show-password
            v-model="loginForm.password"
            placeholder="密码"
          >
            <i slot="prefix" class="iconfont icondunpai"></i>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="isActive"
            :disabled="isActive"
            type="primary"
            @click="login()"
            style="width: 100%"
            >登录</el-button
          >
        </el-form-item>
        <el-form-item>
          <div class="dfl">
            <el-button
              :loading="isActive"
              :disabled="isActive"
              type="primary"
              @click="$router.push({ path: 'apply' })"
              style="width: 100%"
              >入住申请</el-button
            >
            <el-button
              type="text"
              style="text-align: right"
              @click="childClickPassword"
              >忘记密码</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import store from "@/store/index.js";
import ModifyPassword from "@/components/ModifyPassword2";
// import axios from 'axios'
export default {
  name: "login",
  components: {
    ModifyPassword,
  },
  data() {
    return {
      passwordVisible: false,
      logo: require("@/assets/images/logo.png"),
      // 登录按钮是否禁用/等待
      isActive: false,
      // 定义表单数据对象
      loginForm: {
        username: "", // 用户登录账号
        password: "", // 密码
      },
      // 表单校验
      loginFormRules: {
        username: [{ required: true, message: "账号必填" }],
        password: [{ required: true, message: "密码必填" }],
      },
    };
  },
  methods: {
    childClickPassword() {
      this.passwordVisible = true;
    },
    setPasswordVisible(data) {
      // 修改密码组件传值
      this.passwordVisible = data;
    },
    // 登录系统
    login() {
      this.$refs.loginFormRef.validate((valid) => {
        if (valid) {
          var data = this.loginForm;
          this.$http2
            .post("/merapi/login/login", data)
            .then((res) => {
              // eslint-disable-next-line eqeqeq
              if (res.data.status == "200") {
                var data = res.data.data.shop_info;

                window.localStorage.setItem("shopInfo", JSON.stringify(data));
                store.dispatch("setShopInfoData", data);
                window.localStorage.setItem("token", data.token);

                this.$message({
                  message: res.data.msg,
                  type: "success",
                  duration: 1000,
                });
                this.$router.push("/home"); // 进入首页
              } else if (res.data.code == "100") {
                this.$msgbox({
                  title: "提示",
                  dangerouslyUseHTMLString: true,
                  message: res.data.msg,
                  closeOnClickModal: false,
                  closeOnPressEscape: false,
                  showConfirmButton: true,
           
                })
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                  duration: 5000,
                });
              }
            })
            .catch((err) => {
              return this.$message({
                message: err,
                type: "error",
                duration: 5000,
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dfl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .el-button {
    flex: 1;
  }
}
.login-container {
  background-color: gray;
  background-image: url("../assets/images/login_bg.jpg");
  background-size: cover;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .login-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 400px;
    height: 400px;
    background-color: white;
    padding-bottom: 20px;
    text-align: center;
    img {
      width: 42%;
      margin: 15px 0;
    }
    .el-form {
      width: 75%;
    }
    .el-button--primary {
      background-color: #007aff;
    }
  }
}
</style>
